import React,{ useState, useContext, useEffect } from 'react';
import './App.css';
import { AuthContext } from './context/AuthContext';
import Login from './components/Login';
import Register from './components/Register';
import ResetPassword from './components/ResetPassword';
import { db, auth } from "./firebase";
import { doc, updateDoc, setDoc, deleteDoc, onSnapshot, query, collection, where, serverTimestamp } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { v4 as uuid } from "uuid";
import swal from '@sweetalert/with-react';

function App() {
  document.title = "To-Do App • Home"
  const { currentUser } = useContext(AuthContext);
  const [noTasks, setNoTasks] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState("");
  const [component, setComponent] = useState("Home");
  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null);
  const [timesDone, setTimesDone] = useState(0);

  const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const newItem = async () => {
    if(!currentUser){
      swal({
        title: "LOGIN",
        text: "Please Login To Add Work In Your List!"
      })
    }
    const UniqueID = uuid();
    if(newTask !== ""){
      await setDoc(doc(db, "Tasks", UniqueID), {
        Task: newTask,
        Done: false,
        UserID: currentUser.uid,
        ID:UniqueID,
        date: serverTimestamp(),
      });
      setNewTask("")
    }
  }

  // useEffect(() => {
  //   const GetTask = async() => {
  //     const q = query(collection(db, "Tasks"), where("UserID", "==", currentUser.uid));
  //     const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //       if(querySnapshot.empty){
  //         setTasks("NO TASK");
  //       }else{
  //         let TaskToDo = [];
  //         querySnapshot.forEach((doc) => {
  //           TaskToDo.push(doc.data());
  //           setTasks(TaskToDo);
  //         });
  //       }
  //     })
  //   }
  //   GetTask()
  // },[])

  const GetTask = async() => {
    if(timesDone === 1){
      return false
    }else{
      const q = query(collection(db, "Tasks"), where("UserID", "==", currentUser.uid));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        if(querySnapshot.empty){
          setNoTasks(true);
          setTimesDone(timesDone + 1)
        }else{
          let TaskToDo = [];
          querySnapshot.forEach((doc) => {
            TaskToDo.push(doc.data());
            setNoTasks(false);
            setTasks(TaskToDo);
            setTimesDone(timesDone + 1)
          });
        }
      })
    }
  }
  GetTask()

  const MarkAsDone = async(task) => {
    if(task.Done === false){
      await updateDoc(doc(db, "Tasks", task.ID), {
        Done: true,
      });
    }else{
      await updateDoc(doc(db, "Tasks", task.ID), {
        Done: false,
      });
    }
  }

  const delItem = async(task) => {
    if(tasks.length === 1){
      setNoTasks(true)
      await deleteDoc(doc(db, "Tasks", task.ID));
    }else{
      await deleteDoc(doc(db, "Tasks", task.ID));
    }
  }

  const ChangeComponent = (comp) => {
    setComponent(comp);
  }

  const HandleSignOut = async () => {
    await updateDoc(doc(db, "Users", currentUser.uid), {
      Status: "Offline",
      LogoutTime: combinedCurrentDateTime,
    });
    setNoTasks(true);
    signOut(auth)
  }

  return (
    <>
      {component === "Home"
      ?
        <>
          <div className="AppBody">
            <div className="app-container" id="taskList">
              {currentUser
                ?
                <div className="ProfileDetails">
                  <h1>{currentUser.displayName}</h1>
                  <button onClick={HandleSignOut}>LOGOUT</button>
                </div>
                :
                <div className="ProfileDetails">
                  <button onClick={() => ChangeComponent("Login")}>LOGIN</button>
                </div>
              }
              <h1 className="app-header">TO DO LIST</h1>
              <div className="add-task">
                <input
                  type="text"
                  autoComplete="on"
                  placeholder="ADD NEW TASK"
                  value={newTask}
                  onChange={(e) => setNewTask(e.target.value)}
                  onKeyUp={(e) => e.key === "Enter" && newItem()}
                  className="task-input"
                />
                <input
                  type="submit"
                  value=""
                  className="submit-task"
                  onClick={newItem}
                  title="ADD TASK"
                />
              </div>
              <ul className="task-list">
                {noTasks === true
                ?
                <li className="task-list-item-empty">
                  <span className="uppercase">No Work To Do</span>
                </li>
                :
                <>
                  {tasks.map((task) => (
                    <li className="task-list-item">
                      <label className="task-list-item-label">
                        <input type="checkbox" onClick={()=>MarkAsDone(task)} id="Checklist"/>
                        {document.getElementById('Checklist') ? task.Done === true ? document.getElementById('Checklist').checked = true : document.getElementById('Checklist').checked = false : null}
                        <span>{task.Task}</span>
                      </label>
                      <span onClick={() => delItem(task)} className="delete-btn" title="Delete Task">
                        {task.del}
                      </span>
                    </li>
                  ))}
                </>
                }
              </ul>
            </div>
          </div>
        </>
      :
      component === "Login"
      ?
        <Login UpdateComponent={(value) => ChangeComponent(value)}/>
      :
        component === "Register"
        ?
          <Register UpdateComponent={(value) => ChangeComponent(value)}/>
        :
          component === "ForgotPassword"
          &&
            <ResetPassword UpdateComponent={(value) => ChangeComponent(value)}/>
      }
    </>
  );
}

export default App;
