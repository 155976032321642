import React, { useState } from 'react';
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from '../firebase';

const ResetPassword = (props) => {
    document.title = "Drake Chat • Forgot Password"

    const [err, setErr] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorCode, setErrorCode] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    // const {currentUser}=useContext(AuthContext);

    // const input = document.getElementById("RESETPASSWORD")
    // input.setAttribute('size',input.getAttribute('placeholder').length);

    const HandleFormSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        const EMailID = e.target[0].value;

        sendPasswordResetEmail(auth, EMailID)
            .then(() => {
                alert("Password Reset E-Mail Has Been Sent To Your Account => " + EMailID)
            })
            .catch((error) => {
                setErr(true);
                setErrorCode(error.code);
                setErrorMessage(error.message);
            });
    }

    return (
        <>
            <div className="ChangePassword">
                <div className="ChangePasswordContainer">
                    <button id="BackButton" onClick={() => props.UpdateComponent("Login")}>Go Back</button>
                    <h1>Reset Password</h1>
                    <form onSubmit={HandleFormSubmit}>
                        <input id="RESETPASSWORD" type="text" placeholder="Enter Your Registered E-Mail ID" required />
                        {loading && <button disabled="true">Sending Password Reset Link</button>}
                        {!loading && <button>Send Password Reset Link</button>}<br />
                        {err && <span style={{ color: 'red' }}>Something Went Wrong!!</span>}<br />
                        {err && <span style={{ color: 'red' }}>{errorCode}</span>}
                        {/* {err && <span style={{color:'red'}}>{errorCode} : {errorMessage}</span>} */}
                    </form>
                </div>
            </div>
        </>
    )
}
export default ResetPassword