import React, { useState, useEffect } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from '../firebase';
import { doc, setDoc } from "firebase/firestore";

const Register = (props) => {
  document.title = "To-Do App • Register"

  const [err, setErr] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [combinedCurrentDateTime, setCombinedCurrentDateTime] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      var current = new Date();
      var CurrentTime = current.toLocaleString([], { hour12: true, hour: '2-digit', minute: '2-digit' });
      var CurrentDate = current.toLocaleDateString();
      setCombinedCurrentDateTime(CurrentDate + " " + CurrentTime)
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const HandleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const displayName = e.target[0].value;
    const EMail = e.target[1].value;
    const Password = e.target[2].value;
    try {
      const res = await createUserWithEmailAndPassword(auth, EMail, Password);
      await updateProfile(res.user, {
        displayName
      });
      await setDoc(doc(db, "Users", res.user.uid), {
        uid: res.user.uid,
        displayName,
        EMail: EMail,
        Status: "Active Now",
        Password: Password,
        LoginTime:combinedCurrentDateTime,
        LogoutTime:'',
      });
      props.UpdateComponent("Home");
    } catch (err) {
        setErr(true);
        setErrorCode(err.code);
        setErrorMessage(err.message);
        setLoading(false);
      }
  }

  return (
    <>
      <body id="FormBody">
        <div className="login">
          <div className="login__content">
            <div className="login__img">
              <img src="https://raw.githubusercontent.com/bedimcode/responsive-login-signin-signup/b3c2eaa19d76624092bd606d28fbd616d539de92/assets/img/img-login.svg" alt="" />
            </div>

            <div className="login__forms loginform_reg">
                <form onSubmit={HandleSubmit} className="login__registre" id="login-in">
                    <h1 className="login__title">CREATE ACCOUNT</h1>

                    <div className="login__box">
                        <i className="bx bx-user login__icon"></i>
                        <input type="text" placeholder="Username" className="login__input" required/>
                    </div>

                    <div className="login__box">
                        <i className="bx bx-at login__icon" ></i>
                        <input type="email" placeholder="E-Mail" className="login__input" required/>
                    </div>

                    <div className="login__box">
                        <i className="bx bx-lock-alt login__icon"></i>
                        <input type="password" placeholder="Password" className="login__input" required/>
                    </div>
                    {!loading && err && <span className="text-red-500 uppercase font-extrabold">Something went wrong</span>}<br />
                    {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorCode} {errorMessage}</span>}
                    {/* {!loading && err && <span className="text-red-500 uppercase font-extrabold">{errorMessage}</span>} */}
                    {loading && <button className="login__button cursor-pointer w-full" disabled="true">Creating Account</button>}
                    {!loading && <button className="login__button cursor-pointer w-full">Create Account</button>}
                    {!loading &&
                    <>
                        <div>
                            <span className="login__account">Already a User? </span>
                            <span className="login__signin cursor-pointer" id="sign-up" onClick={() => props.UpdateComponent("Login")}>Sign In</span>
                            <br />
                            <span className="login__signin cursor-pointer" id="sign-up" onClick={() => props.UpdateComponent("Home")}>RETURN HOME</span>
                        </div>
                    </>
                    }
                </form>
            </div>
          </div>
        </div>
      </body>
    </>
  )
}

export default Register